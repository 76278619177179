<template>
  <!-- 社团活动-->
  <div class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" :inline="true" v-if="manage||chief" :model="queryParams">
        <el-form-item class="area_img">
          <!-- <img class="area_select" src="@/assets/images/operate/area_select.svg" alt=""> -->
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item prop="status">
          <el-select size="small" label-width="150px" clearable filterable placeholder="请选择活动状态" v-model="queryParams.activityStatusNo" @change="resetPageAndRefresh">
            <el-option v-for="item in statusOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select size="small" label-width="150px" clearable filterable placeholder="请选择活动类型" v-model="queryParams.type" @change="resetPageAndRefresh">
            <el-option v-for="item in typeOptions" :key="item.status" :label="item.lable" :value="item.status"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input size="small" v-model="queryParams.topic" clearable placeholder="请输入活动名称" @change="resetPageAndRefresh"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search" @click="resetPageAndRefresh">查询</el-button>
        </el-form-item>
      </el-form>

      <!-- 列表 -->
      <div v-if="storeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="storeList" :isEditor="isEditor" :total="storeTotal" :span='12' @refresh="refresh">
        <template slot-scope="scope">
          <div class="list"  @click="detail(scope.row)">
            <div class="con-head">
              <div class="area">
                <p class="con-txt" v-if="scope.row.type==0">公益</p>
                <p class="con-txt" v-else-if="scope.row.type==1">竞赛</p>
                <p class="con-txt" v-else>其他</p>
                <p class="mission">活动类型</p>
              </div>
              <div class="area fr">
                <p class="con-txt primary" v-if="scope.row.activityStatusNo ==1">待开始报名</p>
                <p class="con-txt warning" v-else-if="scope.row.activityStatusNo==2">报名中</p>
                <p class="con-txt grey" v-else-if="scope.row.activityStatusNo==3">报名截止</p>
                <p class="con-txt grey" v-else-if="scope.row.activityStatusNo==4">已取消</p>
                <p class="con-txt success" v-else-if="scope.row.activityStatusNo==5">进行中</p>
                <p class="con-txt grey" v-else>已结束</p>
                <p class="mission fr">活动状态</p>
              </div>
              <div class="area">
                <p class="con-txt" :title="scope.row.topic">{{ scope.row.topic?scope.row.topic:'-' }}</p>
                <p class="mission">活动名称</p>
              </div>
              <div class="area">
                <p class="con-txt" :title="scope.row.clubName">{{ scope.row.clubName?scope.row.clubName:'-' }}</p>
                <p class="mission">所属社团</p>
              </div>
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">发布时间</span>
                  <span class="condel">{{ scope.row.addTime }}</span>
                </div>
                <div class="time" v-if="scope.row.extend">
                  <span class="contit">学校名称</span>
                  <span class="condel">{{ scope.row.extend.schoolName }}</span>
                </div>
                <div class="time">
                  <span class="contit">报名开始</span>
                  <span class="condel">{{ scope.row.joinStartTime }}</span>
                </div>
                <div class="time">
                  <span class="contit">开始时间</span>
                  <span class="condel">{{ scope.row.startTime }}</span>
                </div>
                <div class="time">
                  <span class="contit">活动地址</span>
                  <span class="condel">{{ scope.row.address }}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time" v-if="scope.row.extend">
                  <span class="contit">发布人</span>
                  <span class="condel">{{ scope.row.extend.addUserName }}</span>
                </div>
                <div class="time">
                  <span class="contit">活动奖项</span>
                  <span class="condel">{{ scope.row.prizeName }}</span>
                </div>
                <div class="time">
                  <span class="contit">报名结束</span>
                  <span class="condel">{{ scope.row.joinEndTime }}</span>
                </div>
                <div class="time">
                  <span class="contit">结束时间</span>
                  <span class="condel">{{ scope.row.endTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </paged-list>

    </div>
    <!-- 分解任务详情 -->
    <el-drawer
      title="任务详情"
      :visible.sync="drawer"
      size="36.5%"
      >
      <div class="details">
        <div class="d-head">
          <div class="headtit">
            <div class="area">
              <p class="con-txt" v-if="clubActivity.type==0">公益</p>
              <p class="con-txt" v-else-if="clubActivity.type==1">竞赛</p>
              <p class="con-txt" v-else>其他</p>
              <p class="mission">类型</p>
            </div>
            <div class="area fr">
              <div class="con-primary" v-if="clubActivity.activityStatusNo==1">待开始报名</div> 
              <div class="con-warning" v-else-if="clubActivity.activityStatusNo==2">报名中</div>
              <div class="con-9" v-else-if="clubActivity.activityStatusNo==3">报名截止</div>
              <div class="con-9" v-else-if="clubActivity.activityStatusNo==4">已取消</div>
              <div class="con-success" v-else-if="clubActivity.activityStatusNo==5">进行中</div>
              <div class="con-9" v-else>已结束</div>
              <p class="mission fr">状态</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ clubActivity.clubName }}</p>
              <p class="mission">所属社团</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ clubActivity.addTime }}</p>
              <p class="mission">发布时间</p>
            </div>
          </div>
          <div class="headcon">
            <p class="contit">发布人</p>
            <el-image :src="userInfo.headImg" class="pig" alt=""></el-image>
            <div class="area" v-if="userInfo.extend">
              <p class="con-txt">{{ userInfo.extend.userName }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="userInfo.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
              <p class="mission">{{ userInfo.extend.schoolName }}｜{{ userInfo.fade }}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ userInfo.extend.mobile }}</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">{{ userInfo.extend.schoolName }}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
        </div>

        <div class="d-bodyFirst">
          <div class="taskcon">
            <div>
              <p class="contit">活动内容</p>
              <div class="conitem">
                <div class="conlable">报名开始</div>
                <div class="contxt">{{ clubActivity.joinStartTime }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">报名结束</div>
                <div class="contxt">{{ clubActivity.joinEndTime }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">开始时间</div>
                <div class="contxt">{{ clubActivity.startTime }}</div>
              </div>
              <div class="conitem">
                <div class="conlable">结束时间</div>
                <div class="contxt">{{ clubActivity.endTime }}</div>
              </div>
              <div class="conitem1">
                <div class="conlable">活动奖项</div>
                <div class="contxt">{{ clubActivity.prizeName }}</div>
              </div>
              <div class="conitem1">
                <div class="conlable">活动地址</div>
                <div class="contxt">{{ clubActivity.address }}</div>
                <el-button class="fr"  @click="cVisible = true">查看地址</el-button>
              </div>
              <div class="conimg">
                <div>{{ clubActivity.content }}</div>
                <div class="connum">
                  <el-image :src="item" alt="" v-for="(item, index) in clubActivity.imgList" :key="index"></el-image>
                </div>
              </div>
            </div>
            <div>
              <p class="contit">报名列表  &nbsp;<span style="color: #999;">共 {{ getUserList.length }} 位</span></p>
              <div class="imgbg">
                <div v-for="item in getUserList" :key="item.id" class="imgitem">
                  <el-image :src="item.headImg" class="pig" alt=""></el-image>
                  <div class="area">
                    <p class="con-txt">{{ item.name }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="item.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ item.schoolName }}｜{{ item.fade }}</p>
                  </div>
                  <div class="area fr">
                    <p class="con-txt"> &nbsp;</p>
                    <p class="mission">报名时间：{{ item.addTime }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="reachBtn">
              <el-button type="success" v-if="clubActivity.activityStatusNo==5" @click="active=0,clockListRequire('2')">打卡情况</el-button>
              <el-button v-if="clubActivity.activityStatusNo==6" style="margin-right: 40px" @click="active=0,clockListRequire('2')">打卡情况</el-button>
              <el-button type="success" v-if="clubActivity.activityStatusNo==6" @click="prizeListRequire">查看获奖名单</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 打卡详情 -->
    <el-drawer
      :visible.sync="drawerReach"
      size="36.5%"
      >
      <div slot="title" style="cursor: pointer;"> 
        <i @click="drawerReach = false"> &lt; 打卡情况</i>
      </div>
      <div class="details">
        <div class="d-bodyFirst">
          <div class="taskcon">
            <div>
              <div>
                <el-button :class="[active==0?'active':'','reachCheckBtn']" @click="active=0, clockListRequire('2')">已打卡</el-button>
                <el-button :class="[active==1?'active':'','reachCheckBtn']" @click="active=1, clockListRequire('0')">未打卡</el-button>
              </div>
              <div class="imgbg h870" v-if="getClockList.length ==0">
                <h2 style="text-align: center;">暂无人员打卡情况</h2>
              </div>
              <div class="imgbg h870" v-else>
                <div v-for="item in getClockList" :key="item.id" class="imgitem">
                  <el-image :src="item.headImg" class="pig" alt=""></el-image>
                  <div class="area">
                    <p class="con-txt">{{ item.name }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="item.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ item.schoolName }}｜{{ item.fade }}</p>
                  </div>
                  <div class="area fr" v-if="item.addTime">
                    <p class="mission">打卡时间：{{ item.addTime }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>

    <!-- 获奖详情 -->
    <el-drawer
      :visible.sync="drawerAward"
      size="36.5%"
      >
      <div slot="title" style="cursor: pointer;"> 
        <i @click="drawerAward = false"> &lt; 获奖名单</i>
      </div>
      <div class="details">
        <div class="d-bodyFirst">
          <div class="taskcon" v-if="prizeArr.length==0">
            <h2 style="text-align: center;">暂无获奖情况</h2>
          </div>
          <div class="taskcon" v-else>
            <div v-for="(prize, idex) in prizeArr" :key="idex">
              <div class="awardName">
                {{ prize.prizeName }} <span class="awardName_span">共 {{ prize.prizeNum }} 名</span>
              </div>
              <div class="imgbg1" v-for="item in prizeData" :key="item.id">
                <div class="imgitem0" v-if="item.prizeName == prize.prizeName">
                  <el-image :src="item.headImg" class="pig" alt=""></el-image>
                  <div class="area" v-if="item.extend">
                    <p class="con-txt">{{ item.extend.userName }} <img src="@/assets/images/operate/boy.svg" alt="" v-if="item.sex==1"><img src="@/assets/images/operate/girl.svg" alt="" v-else></p>
                    <p class="mission">{{ item.schoolName }}｜{{ item.fade }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 地图 -->
    <el-dialog title="经纬度选择" :visible.sync="cVisible" fullscreen :close-on-click-modal="false">
      <div class="map-container" v-if="cVisible">
        <maps :lng="parseFloat(clubActivity.longitude)" :lat="parseFloat(clubActivity.latitude)" @finish="setLngLat"></maps>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import Maps from '@/components/common/Maps';
import { mapState, mapActions } from "vuex";
/* eslint-disable no-unused-vars */
export default {
  name:'organizeActivity',
  components: {
    PagedList,
    AreaTree,
    Maps
  },
  mixins: [pageMixin],
  data() {
    return {
      /** 点编辑出现遮罩层 */
      editSucce: true,
      /** 角色权限 */
      member: false, ceo: false, manage: false, chief: true,
      drawer: false,
      drawerReach: false,
      drawerAward: false,
      clubActivity: { extend: {}, },
      userInfo: { extend: {}, },
      getUserList: [],
      getClockList: [],
      prizeArr: [
      ],
      prizeNum: 0,
      prizeData: [],
      getDetail: {},
      active: 0,
      form: {
        name: '',
      },
      queryParams: {
        activityStatusNo: '',
        type: '',
        topic: "",
        province: null,
        city: null,
        area: null,
        schoolId: null,
      },
      statusOptions: [
        { status: "1", lable: '待开始报名' },
        { status: "2", lable: '报名中' },
        { status: "3", lable: '报名截止' },
        { status: "4", lable: '已取消' },
        { status: "5", lable: '进行中' },
        { status: "6", lable: '已结束' },
      ],
      typeOptions: [
        { status: "0", lable: '公益' },
        { status: "1", lable: '竞赛' },
        { status: "2", lable: '其他' },
      ],
      pageApi:'getActivityList',
      insertApi: 'activityAdd',
      editorApi: 'activityEdit',
      deleteApi: 'activityDelete',
    };
  },
  computed: {
    ...mapState("organizeActivity", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("organizeActivity", ["getActivityList", "prizeList", "activityAdd", "activityEdit", "activityLoad", "activityDelete", "activityGetUserList", "activityGetClockList"]),
     setLngLat(val){
      this.$message({
        message: '无法改变位置，只能进行查看',
        type: 'warning'
      });
      this.cVisible = false
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
    // 任务详情返回
    async detail(row) {
      try{
        let res = await this.activityLoad({id: row.id})
        this.clubActivity = res.clubActivity
        this.userInfo = res.user
      }catch(error){}
      try{
        let res = await this.activityGetUserList({ activityId: this.clubActivity.id })
        this.getUserList = res.list
      }catch(error){}
      this.drawer = true
    },
    async clockListRequire(status) {
      try{
        let res = await this.activityGetClockList({ activityId: this.clubActivity.id, status })
        this.getClockList = res.list
      }catch(error){}
      this.drawerReach = true
    },
    async prizeListRequire() {
      try{
        let res = await this.prizeList({ activityId: this.clubActivity.id })
        let priArr = []
        this.prizeData = res.prizeUsers
        this.prizeData.forEach((item, index)=>{
          priArr.push(item.prizeName)
        })
        priArr = Array.from(new Set(priArr))
        this.prizeArr = []
        priArr.forEach((item,index)=>{
          let flag = 0
          this.prizeData.forEach((i, dex)=>{
            if(item == i.prizeName){
              flag++
            }
          })
          this.prizeArr.push({prizeNum: flag, prizeName:item})
        })
        console.log(this.prizeArr)
      }catch(error){}
      this.drawerAward = true
    }
  },
  async mounted() {
    this.refresh ()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .select{
      .el-form-item { margin-bottom: 17px; }
      /deep/.el-input__icon{ height: auto; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtwidth{ width: 240px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .condel{color: $c-2; width: 200px; display: inline-block; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        }
      }
    }
  }
}
.margin0 { margin: 0 !important; }
.grey { color: $c-9 !important; }
.primary { color: $c-primary !important; }
.warning { color: $c-warning !important; }
.success { color: $c-success !important; }

/deep/#el-drawer__title{ background:$c-title;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 20px;padding: 20px; }
.details{ width: 100%; height: 100%; background: #fff; padding: 0 20px 20px 20px ;
  .d-head{ color: $c-9; line-height: 14px; font-size: 14px;
    .headtit { color: $c-2; line-height: 18px; font-size: 18px; line-height: 18px; background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 16px;
        .con-txt, .con-primary, .con-warning, .con-9, .con-success { line-height: 20px; font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222; }
        .con-primary { color: $c-primary; }
        .con-warning { color: $c-warning; }
        .con-success { color: $c-success; }
        .con-9 { color: $c-9; }
        .mission{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-1; padding: 20px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 22px; }
      .area{ display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222; }
        .mission{ line-height: 12px; font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px; }
      }
    }
  }
  .d-bodyFirst{ 
    .taskcon { padding: 10px 0;
      .contit{ color: $c-1; padding: 10px 0; line-height: 14px; font-size: 14px; font-weight: bold; }
      .conitem, .conitem1 { display: inline-block; width: 50%; line-height: 14px;
        .conlable { display: inline-block; line-height: 14px; font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px; margin-right: 20px; }
        .contxt { line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC;color: #222; display: inline-block; }
      }
      .conitem1 { width: 100%; 
        .adress { color: $c-primary; text-decoration: underline; cursor: pointer; }
      }
      .conimg { display: inline-block; margin-top: 15px;
        .connum{ display: inline-block;  margin-top: 20px; 
          .el-image { display: inline-block; width: 28%; margin-right: 20px; border-radius: 4px; }
          &:nth-child(3n-1) { margin-right: 0; }
        }
      }
      .imgbg, .imgbg1 { width: 100%; min-height: 0; max-height: 300px; background: $c-border; border-radius: 4px; overflow-y: scroll; 
        .imgitem, .imgitem0 { margin-bottom: 40px; padding: 20px;
          .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 22px; }
          .area{ display: inline-block; margin-right: 16px;height:50px;
            .con-txt{ line-height: 14px; font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222; }
            .mission{ line-height: 12px; font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px; }
          }
        }
        .imgitem:last-child{ margin-bottom: 20px; }
      }
      .imgbg1 { background: none; height: 100%; overflow-y: visible !important; 
      .imgitem0 { margin: 0; }
      }
      .reachCheckBtn { width: 45%; margin-right: 20px; margin-bottom: 20px;}
      .active { color: #27a987; border-color: #27a987; outline: 0; background-color: #eaf8f4; }
      .h870{ min-height: 0; max-height: 600px; }
      .reachBtn { margin-top: 40px; text-align: center; 
        .el-button {width: 140px;}
      }
      .awardName { background: $c-border; width: 100%; height: 60px; line-height: 60px; font-size: 20px; font-weight: bold; padding-left: 20px;
        .awardName_span { font-size: 14px; color: $c-9; }
      }
    }
  }
}
</style>